<template>
    <modal :visible="isParentModalOpen" :close-action="'NULL'" :is-shadow-closable="false">
      <div :class="['container', 'relative']">
        <section :class="['container-header', 'mb-5']">
          <h1 :class="['container-header--title--secondary']">
            <span :class="['bold']">
              Liens de parentalité
            </span>
          </h1>
          <hr :class="['absolute', 'w-full']" :style="{ top: '80px', left: '0', right: '0' }" />
        </section>
  
        <div :class="['p-10', 'text-center', 'flex', 'flex-col']">
          <div :class="['flex', 'flex-row', 'items-center', 'justify-center']">
            <ContractsRecursiveList
              v-if="parentality" 
              :items="parentality"
              :level="0"
            />
          </div>
        </div>
  
        <div :class="['flex', 'items-center', 'row', 'justify-center', 'my-10']">
          <button
          :class="['btn', 'btn-outline--primary', 'mx-5']"
          @click="handleClose"
        >
          Fermer
        </button>
        </div>
      </div>
    </modal>
  </template>
    
  <script>
  import Modal from '@/components/commons/Modal'
  import SelectFilter from '@/components/commons/SelectFilter.vue'
  import axios from '@/axios'
  import ContractsRecursiveList from '@/components/customs/contracts/RecursiveList.vue';
  
  export default {
    components: {
      Modal,
      SelectFilter,
      ContractsRecursiveList
    },

    props: {
      isParentModalOpen: {
        type: Boolean,
        default: false,
      },
      id: {
        type: String | Number | null,
        default: null,
      }
    },
  
    data: () => ({
      parentality: [],
    }),

    watch: {
      isParentModalOpen(newVal, oldVal){
        console.log(newVal)
        if (!newVal || newVal === oldVal) return;

        this.fetchParentality(this.id)
      }
    },
  
    methods: {
      /**
       * Close action from parent
       * @return {void}
       */
      handleClose(){
        this.$emit('closeModalParent')
      },
      /**
       * Fetch fetchParentality
       * @param {number|string} id
       * @return {void}
       */
      async fetchParentality(id){
        try {
          const response = await axios.get(`${process.env.VUE_APP_API_VERSION}/contract/${id}/parentality`)

          this.parentality = [response.data]
        } catch (err) {
          console.error(err)
        }
      },
    }
  }
  </script>
