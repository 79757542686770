<template>
  <button 
    :class="['btn', 'btn-outline--primary-bluer', 'inline-flex', 'mb-4', 'flex', 'flex-row', 'items-center', 'relative', { 'loading': isLoading }]"
    :disabled="isLoading"
    :style="isLoading ? {justifyContent: 'space-between', cursor: 'none'} : { justifyContent: 'space-between'}"
    @click="$refs.file.click()"
  > 
    <input
      :id="`field_supporting_document_upload_${$parent._uid}`"
      ref="file"
      type="file"
      hidden
      @change="uploadFile"
    />
    <span>Ajouter une ou des pièces jointes</span>
    <img
      v-if="!isLoading"
      src="@/assets/images/icons/attachment.svg"
      alt="pièce_jointe"
      width="15"
      class="mr-1"
    />
    <img
      v-else
      src="@/assets/images/icons/loader.svg"
      alt="loader"
      width="20"
      :style="{position: 'absolute', right: '18px'}"
    />
  </button>
</template>

<script>
import $axios from "@/axios";

export default {
  data: () => ({
    fileName: '',
    isLoading: false,
  }),

  mounted() {
    this.fileName = this.title
  },

  methods: {
    async uploadFile(event) {
      this.isLoading = true;
      const file = event.target.files[0]
      const params = new FormData()
      params.append('file', file)

      try {
        const response = await $axios.post(
          '/file/upload/node/document/field_file?_format=json',
          params,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
              'Content-Disposition': `file; filename="${ encodeURI(file.name) }"`,
              'X-CSRF-Token': this.$store.state.authentication.xcsrf_token,
            },
          }
        );

        this.$emit('upload', response)

        this.$toast.add({
          severity: 'success',
          summary: 'Succès',
          detail: 'La pièce jointe a été uploadée.',
          life: 5000,
        });
      } catch (error) {
        this.$toast.add({
          severity: 'error',
          summary: 'Erreur',
          detail: error.response?.data?.message || 'Une erreur est survenue',
          life: 5000,
        });
      } finally {
        this.isLoading = false;
      }
    },
  },
}
</script>

<style scoped>
@keyframes loadingBackground {
  0% { background-color: white; opacity: .75;}
  50% { background-color: #e6eff7; opacity: .35;}
  100% { background-color: white; opacity: .75;}
}

.loading {
  animation: loadingBackground 1.5s infinite;
}
</style>
