<template>
  <div class="contracts-listing wrapper">
    <div class="container">
      <section class="container-header">
        <div class="container-header--title">
          <icon
            icon="liste-contrats"
            size="md" />
          <h1>LISTE DES CONTRATS</h1>
        </div>
        <div class="text-center">
          <button
            class="btn btn-dark btn-small mx-auto mt-3 sm:hidden"
            @click.stop="onFilterClick"
          >
            Filtrer
          </button>
        </div>
      </section>
    </div>

    <div class="background">
      <div :class="['subcontainer-large', listing.length ? 'flex-col-reverse' : 'flex-col']">
        
        <div
          :class="['contracts-listing__table']"
          :style="{ marginTop: !listing.length ? '25px' : 0}"
        >
          <ContractsTableListing
            :contracts="contractsUrgents"
            :urgent="true"
            @emitModalParentId="openModalParent"
          />
          <ContractsTableListing
            :contracts="contracts"
            :headerSorting="!contractsUrgents.length"
            @emitModalParentId="openModalParent"
          />

          <!-- LISTE VIDE -->
          <section
            :class="['flex', 'flex-col', 'justify-center']"
            v-show="isNoContractsCreated"
          >
            <div :class="['container-header--title--secondary']">
              <span :class="['bold']">
                Aucun contrat n'a été créé.
              </span>
            </div>

            <p :class="['text-center']">
              Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Integer et gravida turpis, imperdiet dapibus sapien. Sed semper eu dolor vel feugiat. Suspendisse molestie, justo semper vulputate feugiat, ante urna consectetur nibh, a luctus est ligula non elit. Suspendisse accumsan fringilla enim, nec pretium eros finibus ut. Fusce ac venenatis diam. Morbi neque est, malesuada sed justo ac, molestie euismod orci.
            </p>

          </section>
          <section
            :class="['flex', 'flex-col', 'justify-center']"
            v-show="isNoResults"
          >
            <div :class="['container-header--title--secondary']">
              <span :class="['bold']">
                Aucun résultat.
              </span>
            </div>
          </section>
        </div>
        
        <div
          :class="[
            'flex', 
            'flex-row', 
            'items-center', 
            listing.length ? 'justify-between' : 'justify-center'
          ]">
          <ContractsModalAddContract v-if="!isNoResults" :class="'text-center'"/>
          <button
            v-if="isNoResults"
            :class="['btn', 'btn-secondary']"
            @click="removeFilters"
          >
            Réinitialiser les filtres
          </button>
          <button
            v-if="listing.length"
            :class="['btn', 'btn-outline--primary-bluer']"
            @click="exportXLSXInteractions()"
          >
            Export XLSX
          </button>
        </div>
      </div>
    </div>

    <ContractsModalConsent />  
    <ContractsModalParent
      :isParentModalOpen.sync="isParentModalOpen"
      :id="targetContractId"
      @closeModalParent="isParentModalOpen = false"
    />  
  </div>
</template>

<script>
import $axios from "@/axios";
import ContractsTableListing from '@/components/customs/contracts/TableListing.vue'
import { mapState } from 'vuex'
import ContractsModalConsent from "@/components/customs/contracts/ModalConsent.vue";
import ContractsModalAddContract from "@/components/customs/contracts/ModalAddContract.vue";
import ContractsModalParent from "@/components/customs/contracts/ModalParent.vue";

export default {
  components: {
    ContractsTableListing,
    ContractsModalConsent,
    ContractsModalAddContract,
    ContractsModalParent,
  },
  
  computed: {
    ...mapState('contracts', {
      loading: state => state.loading,
      listing: state => state.listing,
    }),
    contractsUrgents() {
      return this.listing.filter((contract) => {
        return contract.notificationDateExceeded
      })
    },
    contracts() {
      return this.listing.filter((contract) => {
        return !contract.notificationDateExceeded
      })
    },
    isFiltersActive(){
      return !(Object.keys(this.$route.query).length === 0 && this.$route.query.constructor === Object)
    },
    isNoResults(){
      return this.listing.length === 0 && this.isFiltersActive
    },
    isNoContractsCreated(){
      return this.listing.length === 0 && !this.isFiltersActive
    }
  },

  data() {
    return {
      isParentModalOpen: false,
      targetContractId: null,
    }
  },

  watch: {
    '$route.query'() {
      this.fetch()
    }
  },

  beforeMount () {
    this.fetch()
  },

  mounted(){
    // Pour une raison X ou Y, l'api renvoi pas l'item de contrat nouvellement créé lorsqu'on revient sur 
    setTimeout(() => {
      this.fetch();
    }, 3000);
  },

  methods: {
    /**
     * Fetch Action
     * @return {void}
     */
    fetch() {
      this.$store.dispatch('contracts/getListing', this.$route.query)
    },
    /**
     * onFilterClick
     * @return {void}
     */
    onFilterClick() {
      this.$store.dispatch('drawerStatus', true)
    },
    /**
     * openModalParent Action
     * @param {string|number} id
     * @return {void}
     */
    openModalParent(id){
      this.targetContractId = id

      this.isParentModalOpen = true;
    },
    /**
     * closeModalParent Action
     * @param {object} data
     * @return {void}
     */
    closeModalParent(){
      this.isParentModalOpen = true;
    },
    /**
     * Fetch Interactions List and export XLSX
     * @return {Promise<void>}
     */
    async exportXLSXInteractions(){
      try {
        this.$route.query.xlsx = true
        const params = { ...this.$route.query }

        let facets = {
          f: []
        }

        for (const [i, entry] of Object.entries(params)) {
          switch (i) {
            case 'contract_status':
            case 'contract_nature':
            case 'purchase_category':
            case 'automatic_renewal':
              const values = entry.split(',')
              
              for(const value of values) {
                facets.f.push(`${i}:${value}`)
              }

              delete params[i]
              break
              default:
                break;
          }
        }

        const requestParams = {
          ...facets,
          ...params
        }

        const content = await $axios.get(`${process.env.VUE_APP_API_VERSION}/contracts`, {
          params: requestParams
        })

        this.excelContent = content.data.rows

        const sheet = this.$xlsx.utils.json_to_sheet(this.excelContent)
        const wb = this.$xlsx.utils.book_new()

        this.$xlsx.utils.book_append_sheet(wb, sheet, 'Feuille 1')
        // export Excel file
        this.$xlsx.writeFile(wb, 'liste_des_contrats.xlsx')           
      } catch (err) {
        console.error(err)
      }
    },
    /**
     * Remove filters Action
     * @param {object} data
     * @return {void}
     */
    async removeFilters() {
      const query = { motclef: this.$route.query.motclef }
      await this.$router.replace({ query })

      this.$store.commit('RESET_AUTOCOMPLETES_VALUES')
    },
  }
}
</script>
