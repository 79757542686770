<template>
  <aside
    class="page-drawer"
    v-if="isOpen"
    v-click-outside="handleClose"
  >
    <sort-by-alphabetical-order 
      v-if="$route.meta.hasAlphabetizer" 
    />
    <sort-by-favorite 
      v-if="userRole === 'supplier' && $route.meta.hasFavorite"
    />
    <active-filter-pills 
      v-if="
        $route.name === 'subscribers' || 
        $route.name === 'subscribersSearch' ||
        $route.name === 'roadmap' ||
        $route.name === 'contracts'
      " 
      :state-key="
        $route.name === 'subscribers' || $route.name === 'subscribersSearch' 
        ? 'subscribers' 
        : $route.name
      "
    />

    <div 
      v-if="
        $route.name === 'subscribers' || 
        $route.name === 'subscribersSearch'
      " 
      :class="['title']"

    >
      Filtrer
    </div>
    <FiltersTitleHandler 
      v-else-if="$route.name === 'roadmap' || $route.name === 'contracts'" 
      :always-closable="false"
      :class="['pt-8', 'px-8', $route.name === 'contracts' ? 'pb-4' : null]"
    />

    <autocomplete-filters 
      v-if="$route.name === 'subscribers' || $route.name === 'subscribersSearch'"
      :filters="autocompleteFilters" 
      state-key="subscribers"
    />
    <checkboxes-families 
      v-if="($route.meta.hasCheckboxes && hasSelectedCategory)"
    />
    <checkboxes-families-result
      v-if="$route.name === 'result'"
    />
    <indice-detail-filters
      v-if="$route.name === 'indice-detail'"
    />
    <indices-listing-families
      v-if="$route.name === 'indices-listing'"
    />
    <other-suppliers-families
      v-if="$route.name === 'result'"
    />
    <navigation-categories
      v-if="!hasSelectedCategory && isListOfSuppliersPage"
    />
    <navigation-drawer
      v-if="$route.meta.hasNavigation"
    />
    <select-filters-list 
      v-if="$route.meta.hasFilters"
      :state-key="
        $route.name === 'subscribers' || $route.name === 'subscribersSearch' 
        ? 'subscribers' 
        : $route.name
      "
    />
    <contracts-dates-filters
      ref="contractsDateFilters"
      v-if="$route.name === 'contracts'"
    />
    <evaluate-supplier 
      v-if="$route.meta.hasEvaluation"
    />
  </aside>
</template>

<script>
import ClickOutside from 'vue-click-outside'

import SortByAlphabeticalOrder from './drawer/SortByAlphabeticalOrder.vue'
import SortByFavorite from './drawer/SortByFavorite.vue'
import CheckboxesFamilies from './drawer/CheckboxesFamilies.vue'
import CheckboxesFamiliesResult from './drawer/CheckboxesFamiliesResult.vue'
import IndiceDetailFilters from './drawer/IndiceDetailFilters.vue'
import IndicesListingFamilies from './drawer/IndicesListingFamilies.vue'
import NavigationDrawer from './drawer/NavigationDrawer.vue'
import NavigationCategories from './drawer/NavigationCategories.vue'
import OtherSuppliersFamilies from './drawer/OtherSuppliersFamilies.vue'
import SelectFiltersList from './drawer/SelectFiltersList'
import EvaluateSupplier from './drawer/EvaluateSupplier'
import ActiveFilterPills from "@/layout/blocks/drawer/ActiveFilterPills";
import AutocompleteFilters from "@/layout/blocks/drawer/AutocompleteFilters";
import FiltersTitleHandler from './drawer/FiltersTitleHandler';
import ContractsDatesFilters from './drawer/ContractsDatesFilters.vue'

export default {
  components: {
    FiltersTitleHandler,
    AutocompleteFilters,
    ActiveFilterPills,
    SortByAlphabeticalOrder,
    SortByFavorite,
    CheckboxesFamilies,
    CheckboxesFamiliesResult,
    IndiceDetailFilters,
    IndicesListingFamilies,
    NavigationDrawer,
    NavigationCategories,
    OtherSuppliersFamilies,
    SelectFiltersList,
    EvaluateSupplier,
    ContractsDatesFilters
  },

  data: function () {
    return {
      windowWidth: window.innerWidth,
      autocompleteFilters: [
        {
          name: 'naf',
          placeholder: 'Par code NAF'
        },
        {
          name: 'affiliate',
          placeholder: 'Par maison mère',
          entityType: 'node'
        },
      ]
    }
  },

  computed: {
    isOpen () {
      return this.$store.state.isDrawerOpen || (this.$route.name !== 'indice-detail' && window.innerWidth >= 1220)
    },

    isSupplierPage () {
      return this.$route.name === 'supplier' ||
      this.$route.name === 'supplierPresentation' ||
      this.$route.name === 'supplierOffers' ||
      this.$route.name === 'supplierDocuments' ||
      this.$route.name === 'supplierCards' ||
      this.$route.name === 'supplierMembers'
    },

    isListOfSuppliersPage () {
      return this.$route.name === 'suppliers'
    },

    hasSelectedCategory () {
      return !!this.$route.params.slug
    },

    hasFamilies () {
      return this.$store.state.categories.current.families ? this.$store.state.categories.current.families.length !== 0 : true
    },

    userRole () {
      return this.$store.getters.userRole
    }
  },

  methods: {
    handleClose () {
      this.$store.dispatch('drawerStatus', false)
    },
    updateWindowWidth () {
      this.windowWidth = window.innerWidth;
    },
    cleanInputsDateContracts(ctx){
      if (ctx === 'notification_date'){
        this.$refs.contractsDateFilters.notificationDate = null;
      }
      else if (ctx === 'end_date'){
        this.$refs.contractsDateFilters.endDate = null;

      } else {
        this.$refs.contractsDateFilters.notificationDate = null;

        this.$refs.contractsDateFilters.endDate = null;
      }
    }
  },

  mounted() {
    if (this.$route.name === 'roadmap'){
      window.addEventListener('resize', this.updateWindowWidth);
    }
  },

  beforeDestroy() {
    if (this.$route.name === 'roadmap'){
      window.removeEventListener('resize', this.updateWindowWidth);
    }
  },

  watch: {
    windowWidth (newWidth) {
      if (newWidth >= 1220) {
        this.$store.dispatch('drawerStatus', true);
      }
    },
    $route(newVal, oldVal){
      if (!oldVal.query || !newVal.query) return;

      const keysToWatch = ["end_date", "notification_date"];

      // Détecter les clés qui étaient présentes dans oldVal mais ont disparu dans newVal
      const removedKeys = keysToWatch.filter(
        key => oldVal.query[key] !== undefined && newVal.query[key] === undefined
      );

      if (removedKeys.length === 1) {
        // Une seule clé a été supprimée, on appelle la méthode avec cette clé
        this.cleanInputsDateContracts(removedKeys[0]);
      } else if (removedKeys.length === 2) {
        // Les deux clés ont été supprimées, on appelle la méthode sans paramètre
        this.cleanInputsDateContracts();
      }
    }
  },

  directives: {
    ClickOutside
  }
}
</script>

<style lang="scss" scoped>
.drawer-back {
  @apply py-6 mb-10;

  &:hover {
    @apply bg-gray-300 cursor-pointer;
  }
}

.title {
  @apply pt-8 px-8 uppercase font-bold;
}
</style>
